import {
  GET_PLAYERS,
  GET_PLAYERS_SUCCESS,
  GET_PLAYERS_ERROR,
  DEPOSITE_REQUEST,
  DEPOSITE_SUCCESS,
  DEPOSITE_ERROR,
  REDEEM_REQUEST,
  REDEEM_SUCCESS,
  REDEEM_ERROR,
  FREE_SPIN_AWARD_REQUEST,
  FREE_SPIN_AWARD_SUCCESS,
  FREE_SPIN_AWARD_ERROR,
  CREATE_PLAYER,
  CREATE_PLAYER_SUCCESS,
  CREATE_PLAYER_ERROR,
  SET_STATE_REQ,
  SET_STATE_SUCCESS,
  SET_STATE_ERROR,
  GET_LOGS,
  GET_LOGS_SUCCESS,
  GET_LOGS_ERROR,
  GET_CU,
  GET_CU_SUCCESS,
  GET_CU_ERROR,
  GET_TRANSACTION,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_ERROR,
  CASHIER_TRANSACTION,
  CASHIER_TRANSACTION_SUCCESS,
  CASHIER_TRANSACTION_ERROR,
  JACKPOT_REQUEST,
  JACKPOT_SUCCESS,
  JACKPOT_ERROR,
  JACKPOT_CATEGORY_REQUEST,
  JACKPOT_CATEGORY_SUCCESS,
  JACKPOT_CATEGORY_ERROR,
  CASHIER_INDEX_REQUEST,
  CASHIER_INDEX_SUCCESS,
  CASHIER_INDEX_ERROR,
  CREATE_CASHIER_REQUEST,
  CREATE_CASHIER_SUCCESS,
  CREATE_CASHIER_ERROR,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_ERROR,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  LANGUAGE_REQUEST,
  LANGUAGE_SUCCESS,
  LANGUAGE_ERROR,
  TIMEZONE_REQUEST,
  TIMEZONE_SUCCESS,
  TIMEZONE_ERROR,
  REVERSE_REQUEST,
  REVERSE_SUCCESS,
  REVERSE_ERROR,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_ERROR,
  STATISTICS_REQUEST,
  STATISTICS_SUCCESS,
  STATISTICS_ERROR,
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_ERROR,
  CHANGE_STATE_REQUEST,
  CHANGE_STATE_SUCCESS,
  CHANGE_STATE_ERROR,
  GET_BOUNCEBACK_REQUEST,
  GET_BOUNCEBACK_SUCCESS,
  GET_BOUNCEBACK_ERROR,
  BOUNCEBACK_LIST_REQUEST,
  BOUNCEBACK_LIST_SUCCESS,
  BOUNCEBACK_LIST_ERROR,
  RECEIPT_REQUEST,
  RECEIPT_SUCCESS,
  RECEIPT_ERROR,
  GET_PAYOUT_REQUEST,
  GET_PAYOUT_SUCCESS,
  GET_PAYOUT_ERROR,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  HAPPY_HOURS_REQUEST,
  HAPPY_HOURS_SUCCESS,
  HAPPY_HOURS_ERROR,
  PERMISSIONS_REQUEST,
  PERMISSIONS_SUCCESS,
  PERMISSIONS_ERROR,
  JACKPOT_DETAILS_REQUEST,
  JACKPOT_DETAILS_SUCCESS,
  JACKPOT_DETAILS_ERROR,
  GAMES_REQUEST,
  GAMES_SUCCESS,
  GAMES_ERROR,
  UPDATE_GAMES_REQUEST,
  UPDATE_GAMES_SUCCESS,
  UPDATE_GAMES_ERROR,
  UPDATE_CASHIER_REQUEST,
  UPDATE_CASHIER_SUCCESS,
  UPDATE_CASHIER_ERROR,
  REPORTS_REQUEST,
  REPORTS_SUCCESS,
  REPORTS_ERROR,
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_ERROR,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_ERROR,
  MESSAGE_REQUEST,
  MESSAGE_SUCCESS,
  MESSAGE_ERROR,
} from "../actions";

export const getPlayers = () => ({
  type: GET_PLAYERS,
});
export const getPlayersSuccess = (users) => ({
  type: GET_PLAYERS_SUCCESS,
  payload: { users: users.data, count: users.pageCount },
});
export const getPlayersError = (message) => ({
  type: GET_PLAYERS_ERROR,
  payload: { message },
});

export const deposite = () => ({
  type: DEPOSITE_REQUEST,
});
export const depositeSuccess = (payload) => ({
  type: DEPOSITE_SUCCESS,
  payload: { player: payload.data, message: payload.message },
});
export const depositeError = (payload, message) => ({
  type: DEPOSITE_ERROR,
  payload: { player: payload.data, message },
});

export const redeem = () => ({
  type: REDEEM_REQUEST,
});
export const redeemSuccess = (payload) => ({
  type: REDEEM_SUCCESS,
  payload: { player: payload.data, message: payload.message },
});
export const redeemError = (message) => ({
  type: REDEEM_ERROR,
  payload: { message },
});

export const freeSpinAward = () => ({
  type: FREE_SPIN_AWARD_REQUEST,
});
export const freeSpinAwardSuccess = (payload) => ({
  type: FREE_SPIN_AWARD_SUCCESS,
  payload: { player: payload.data, message: payload.message },
});
export const freeSpinAwardError = (message) => ({
  type: FREE_SPIN_AWARD_ERROR,
  payload: { message },
});

export const createPlayer = () => ({
  type: CREATE_PLAYER,
});
export const createPlayerSuccess = (payload) => ({
  type: CREATE_PLAYER_SUCCESS,
  payload: { player: payload.data, message: payload.message },
});
export const createPlayerError = (message) => ({
  type: CREATE_PLAYER_ERROR,
  payload: { message },
});

export const setStateReq = () => ({
  type: SET_STATE_REQ,
});
export const setStateSuccess = (payload) => ({
  type: SET_STATE_SUCCESS,
  payload: { player: payload.detail, message: payload.message },
});
export const setStateError = (message) => ({
  type: SET_STATE_ERROR,
  payload: { message },
});

export const getLogs = () => ({
  type: GET_LOGS,
});
export const getLogsSuccess = (payload) => ({
  type: GET_LOGS_SUCCESS,
  payload: { logs: payload.userlogs, count: payload.pageCount },
});
export const getLogsError = (message) => ({
  type: GET_LOGS_ERROR,
  payload: { message },
});

export const getCUReq = () => ({
  type: GET_CU,
});
export const getCUSuccess = (payload) => ({
  type: GET_CU_SUCCESS,
  payload: { cashier: payload },
});
export const getCUError = (message) => ({
  type: GET_CU_ERROR,
  payload: { message },
});

export const getTransaction = () => ({
  type: GET_TRANSACTION,
});
export const getTransactionSuccess = (payload) => ({
  type: GET_TRANSACTION_SUCCESS,
  payload: { list: payload.transactions, count: payload.pageCount },
});
export const getTransactionError = (message) => ({
  type: GET_TRANSACTION_ERROR,
  payload: { message },
});

export const cashierTransaction = () => ({
  type: CASHIER_TRANSACTION,
});
export const cashierTransactionSuccess = (payload) => ({
  type: CASHIER_TRANSACTION_SUCCESS,
  payload: { transactions: payload.transactions, count: payload.pageCount },
});
export const cashierTransactionError = (message) => ({
  type: CASHIER_TRANSACTION_ERROR,
  payload: { message },
});

export const jackpotRequest = () => ({
  type: JACKPOT_REQUEST,
});
export const jackpotSuccess = (payload) => ({
  type: JACKPOT_SUCCESS,
  payload: { jackpot: payload.jackpothistory, count: payload.pageCount },
});
export const jackpotError = (message) => ({
  type: JACKPOT_ERROR,
  payload: { message },
});

export const jackpotCategoryRequest = () => ({
  type: JACKPOT_CATEGORY_REQUEST,
});
export const jackpotCategorySuccess = (payload) => ({
  type: JACKPOT_CATEGORY_SUCCESS,
  payload: { categories: payload },
});
export const jackpotCategoryError = (message) => ({
  type: JACKPOT_CATEGORY_ERROR,
  payload: { message },
});

export const ULRequest = () => ({
  type: USER_LIST_REQUEST,
});
export const ULSuccess = (payload) => ({
  type: USER_LIST_SUCCESS,
  payload: { users: payload.users, count: payload.count },
});
export const ULError = (message) => ({
  type: USER_LIST_ERROR,
  payload: { message },
});

export const CIRequest = () => ({
  type: CASHIER_INDEX_REQUEST,
});
export const CISuccess = (payload) => ({
  type: CASHIER_INDEX_SUCCESS,
  payload: { cashiers: payload },
});
export const CIError = (message) => ({
  type: CASHIER_INDEX_ERROR,
  payload: { message },
});

export const createCashierRequest = () => ({
  type: CREATE_CASHIER_REQUEST,
});
export const createCashierSuccess = (response) => ({
  type: CREATE_CASHIER_SUCCESS,
  payload: { message:response.message, cashier:response.data },
});
export const createCashierError = (message) => ({
  type: CREATE_CASHIER_ERROR,
  payload: { message },
});

export const getUserRequest = () => ({
  type: GET_USER_REQUEST,
});
export const getUserSuccess = (user) => ({
  type: GET_USER_SUCCESS,
  payload: { user },
});
export const getUserError = (message) => ({
  type: GET_USER_ERROR,
  payload: { message },
});

export const languageRequest = () => ({
  type: LANGUAGE_REQUEST,
});
export const languageSuccess = (languages) => ({
  type: LANGUAGE_SUCCESS,
  payload: { languages },
});
export const languageError = (message) => ({
  type: LANGUAGE_ERROR,
  payload: { message },
});

export const timeZoneRequest = () => ({
  type: TIMEZONE_REQUEST,
});
export const timeZoneSuccess = (timeZones) => ({
  type: TIMEZONE_SUCCESS,
  payload: { timeZones },
});
export const timeZoneError = (message) => ({
  type: TIMEZONE_ERROR,
  payload: { message },
});

export const reverseRequest = () => ({
  type: REVERSE_REQUEST,
});
export const reverseSuccess = (message) => ({
  type: REVERSE_SUCCESS,
  payload: { message },
});
export const reverseError = (message) => ({
  type: REVERSE_ERROR,
  payload: { message },
});

export const deleteRequest = () => ({
  type: DELETE_REQUEST,
});
export const deleteSuccess = (message) => ({
  type: DELETE_SUCCESS,
  payload: { message },
});
export const deleteError = (message) => ({
  type: DELETE_ERROR,
  payload: { message },
});

export const statisticsRequest = () => ({
  type: STATISTICS_REQUEST,
});
export const statisticsSuccess = (list) => ({
  type: STATISTICS_SUCCESS,
  payload: { users: list.data, count: list.count },
});
export const statisticsError = (message) => ({
  type: STATISTICS_ERROR,
  payload: { message },
});

export const searchRequest = () => ({
  type: SEARCH_REQUEST,
});
export const searchSuccess = (user) => ({
  type: SEARCH_SUCCESS,
  payload: { user },
});
export const searchError = (message) => ({
  type: SEARCH_ERROR,
  payload: { message },
});

export const getJackpotCategory = () => ({
  type: JACKPOT_CATEGORY_REQUEST,
});
export const getJackpotCategorySuccess = (payload) => ({
  type: JACKPOT_CATEGORY_SUCCESS,
  payload: { categories: payload },
});
export const getJackpotCategoryError = (message) => ({
  type: JACKPOT_CATEGORY_ERROR,
  payload: { message },
});

export const jackpotCatDetails = () => ({
  type: JACKPOT_DETAILS_REQUEST,
});
export const jackpotCatDetailsSuccess = (payload) => ({
  type: JACKPOT_DETAILS_SUCCESS,
  payload: { category: payload.details, message: payload.message },
});
export const jackpotCatDetailsError = (message) => ({
  type: JACKPOT_DETAILS_ERROR,
  payload: { message },
});

export const changeStateRequest = () => ({
  type: CHANGE_STATE_REQUEST,
});
export const changeStateSuccess = (message) => ({
  type: CHANGE_STATE_SUCCESS,
  payload: { message },
});
export const changeStateError = (message) => ({
  type: CHANGE_STATE_ERROR,
  payload: { message },
});

export const getbouncebackRequest = () => ({
  type: GET_BOUNCEBACK_REQUEST,
});
export const getbouncebackSuccess = (payload) => ({
  type: GET_BOUNCEBACK_SUCCESS,
  payload: { list: payload },
});
export const getbouncebackError = (message) => ({
  type: GET_BOUNCEBACK_ERROR,
  payload: { message },
});

export const bouncebackListRequest = () => ({
  type: BOUNCEBACK_LIST_REQUEST,
});
export const bouncebackListSuccess = (payload) => ({
  type: BOUNCEBACK_LIST_SUCCESS,
  payload: { list: payload },
});
export const bouncebackListError = (message) => ({
  type: BOUNCEBACK_LIST_ERROR,
  payload: { message },
});

export const receiptRequest = () => ({
  type: RECEIPT_REQUEST,
});
export const receiptSuccess = (message) => ({
  type: RECEIPT_SUCCESS,
  payload: { message },
});
export const receiptError = (message) => ({
  type: RECEIPT_ERROR,
  payload: { message },
});

export const getPayoutRequest = () => ({
  type: GET_PAYOUT_REQUEST,
});
export const getPayoutSuccess = (payload) => ({
  type: GET_PAYOUT_SUCCESS,
  payload: { list: payload },
});
export const getPayoutError = (message) => ({
  type: GET_PAYOUT_ERROR,
  payload: { message },
});

export const updateRequest = () => ({
  type: UPDATE_REQUEST,
});
export const updateSuccess = ({ message }) => ({
  type: UPDATE_SUCCESS,
  payload: { message },
});
export const updateError = (message) => ({
  type: UPDATE_ERROR,
  payload: { message },
});

export const getHappyhoursRequest = () => ({
  type: HAPPY_HOURS_REQUEST,
});
export const getHappyhoursSuccess = (data) => ({
  type: HAPPY_HOURS_SUCCESS,
  payload: { hours_list: data },
});
export const getHappyhoursError = (message) => ({
  type: HAPPY_HOURS_ERROR,
  payload: { message },
});

export const permissionsRequest = () => ({
  type: PERMISSIONS_REQUEST,
});
export const permissionsSuccess = (data) => ({
  type: PERMISSIONS_SUCCESS,
  payload: { permissions: data },
});
export const permissionsError = (message) => ({
  type: PERMISSIONS_ERROR,
  payload: { message },
});

export const getGamesRequest = () => ({
  type: GAMES_REQUEST,
});
export const getGamesSuccess = (games) => ({
  type: GAMES_SUCCESS,
  payload: { games: games.details, count: games.count },
});
export const getGamesError = (message) => ({
  type: GAMES_ERROR,
  payload: { message },
});

export const updateGamesRequest = () => ({
  type: UPDATE_GAMES_REQUEST,
});
export const updateGamesSuccess = (game) => ({
  type: UPDATE_GAMES_SUCCESS,
  payload: { game: game.game },
});
export const updateGamesError = (message) => ({
  type: UPDATE_GAMES_ERROR,
  payload: { message },
});

export const updateCashierRequest = () => ({
  type: UPDATE_CASHIER_REQUEST,
});
export const updateCashierSuccess = (message) => ({
  type: UPDATE_CASHIER_SUCCESS,
  payload: { message },
});
export const updateCashierError = (message) => ({
  type: UPDATE_CASHIER_ERROR,
  payload: { message },
});

export const reportsRequest = () => ({
  type: REPORTS_REQUEST,
});
export const reportsSuccess = (data) => ({
  type: REPORTS_SUCCESS,
  payload: { list: data },
});
export const reportsError = (message) => ({
  type: REPORTS_ERROR,
  payload: { message },
});

export const requestSettings = () => ({
  type: SETTINGS_REQUEST,
});
export const updateSettings = (data) => ({
  type: SETTINGS_SUCCESS,
  payload: { user: data.result, message: data.message },
});
export const errorSettings = (message) => ({
  type: SETTINGS_ERROR,
  payload: { message },
});

export const dashboardRequest = () => ({
  type: DASHBOARD_REQUEST,
});
export const dashboardSuccess = (data) => ({
  type: DASHBOARD_SUCCESS,
  payload: { data },
});
export const dashboardError = (message) => ({
  type: DASHBOARD_ERROR,
  payload: { message },
});

export const messageRequest = () => ({
  type: MESSAGE_REQUEST,
});
export const messageSuccess = (message) => ({
  type: MESSAGE_SUCCESS,
  payload: { message },
});
export const messageError = (message) => ({
  type: MESSAGE_ERROR,
  payload: { message },
});
