/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
];

export const firebaseConfig = {
  apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
  authDomain: "gogo-react-login.firebaseapp.com",
  databaseURL: "https://gogo-react-login.firebaseio.com",
  projectId: "gogo-react-login",
  storageBucket: "gogo-react-login.appspot.com",
  messagingSenderId: "216495999563",
};

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = "light.purple";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = true;
export const themeColorStorageKey = "__theme_color";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;

export const JWT_TOKEN = "auth_jwt_token";
export const ROLE = "role";
export const SUPER_ADMIN = "is_superadmin";

export const Roles = {
  Admin: "ADMIN",
  Shop: "SHOP",
  Distributor: "DISTRIBUTOR",
  Player: "PLAYER",
  Cashier: "CASHIER",
};

export const buttonText = [
  { label: "Apply Filter", value: 0 },
  { label: "For Today", value: "day" },
  { label: "For Yesterday", value: 1 },
  { label: "This Week", value: "week" },
  { label: "This Month", value: "month" },
];

export const UserType = {
  Classic: "Classic",
  SweepStakes: "Sweepstakes",
};

export const game = {
  flamingo: "Flamingo7",
  river: "River Pay",
};

export const redirectionRoutes = {
  Admin: "/admin/users",
  Shop: "/shop/users",
  Cashier: "/cashier/players",
  Distributor: "/distributor/users",
};

export const gameType = { label: game.flamingo, value: 1, key: 0 };
// { label: game.river, value: 0, key: 1 },

export const accountType = [
  { label: Roles.Distributor, value: Roles.Distributor, key: 0 },
  { label: Roles.Shop, value: Roles.Shop, key: 1 },
  { label: Roles.Admin, value: Roles.Admin, key: 2 },
];

export const accountMode = [
  { label: UserType.Classic, value: UserType.Classic, key: 0 },
  { label: UserType.SweepStakes, value: UserType.SweepStakes, key: 1 },
];
export const statusType = [
  { label: "Disabled", value: 0 },
  { label: "Enabled", value: 1 },
];

export const bonusMultiplier = [
  { label: "*5", value: 5 },
  { label: "*6", value: 6 },
  { label: "*7", value: 7 },
  { label: "*8", value: 8 },
  { label: "*9", value: 9 },
  { label: "*10", value: 10 },
];

export const bonusTime = [
  { label: "60 minutes ", value: 60 },
  { label: "120 minutes ", value: 120 },
  { label: "180 minutes ", value: 180 },
  { label: "240 minutes ", value: 240 },
  { label: "Unlimited ", value: 0 },
];

export const printType = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const printFont = [
  { label: "Graduate", value: "Graduate" },
  { label: "Electrolize", value: "Electrolize" },
  { label: "Arial", value: "Arial" },
  { label: "Times New Roman", value: "Times New Roman" },
];
export const printTemplate = [{ label: "Default", value: "default" }];

export const printStatus = [
  { label: "Disabled", value: false },
  { label: "Enabled", value: true },
];

export const jackpotType = [
  { label: "Shared", value: 1 },
  { label: "Single", value: 0 },
];

export const jackpotStatus = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
  { label: "Deleted", value: 2 },
];

export const hhColumns = [
  { label: "active", value: 1 },
  { label: "start", value: 3 },
  { label: "hours", value: 2 },
  { label: "percent", value: 3 },
  { label: "day", value: 3 },
];

export const hhPercentages = [
  { label: "0 %", value: 0 },
  { label: "10 %", value: 10 },
  { label: "20 %", value: 20 },
  { label: "30 %", value: 30 },
  { label: "40 %", value: 40 },
  { label: "50 %", value: 50 },
  { label: "60 %", value: 60 },
  { label: "70 %", value: 70 },
  { label: "80 %", value: 80 },
  { label: "90 %", value: 90 },
  { label: "100 %", value: 100 },
];

export const hhDays = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];

export const controls = {
  Lock: "lock",
  Delete: "delete",
  Reverse: "reverse",
  Deposit: "deposit",
  Close: "close",
  Redeem: "redeem",
  Awards: "awards",
};

export const colours = {
  Success: "success",
  Danger: "danger",
  Light: "light",
  Warning: "warning",
  Info: "info",
};

export const jackpots = {
  Red: "Red",
  Silver: "Silver",
  Gold: "Gold",
  Diamond: "Diamond",
};

export const searchConst = {
  Players: "players",
  Transactions: "transactions",
  Users: "users",
};

export const tabsConst = [
  { label: "General", value: 1 },
  { label: "Jackpots", value: 2, role1: Roles.Shop, type: "Both" },
  { label: "Bonuses", value: 3, role1: Roles.Shop, type: "Both" },
  { label: "Print Receipts", value: 4, role1: Roles.Shop, type: "Both" },
  { label: "Payout", value: 5, role1: Roles.Shop, type: "Both" },
  { label: "Happy Hours", value: 6, role1: Roles.Shop, type: UserType.Classic },
  { label: "Wager Bonus", value: 7, role1: Roles.Shop, type: UserType.Classic },
  {
    label: "Permission",
    value: 8,
    role1: Roles.Admin,
    role2: Roles.Distributor,
    type: "Both",
  },
];

export const permissionType = [
  { label: "BONUSES", key: "can_set_bonus", value: 1, role: Roles.Distributor },
  {
    label: "JACKPOTS",
    key: "can_set_jackpot",
    value: 2,
    role: Roles.Distributor,
  },
  { label: "PAYOUT", key: "can_set_payout", value: 3, role: Roles.Distributor },
  {
    label: "CREATE SHOP",
    key: "can_create_shop",
    value: 4,
    role: Roles.Distributor,
  },
  {
    label: "HAPPY HOURS",
    key: "can_set_happy_hour",
    value: 5,
    role: Roles.Distributor,
  },
  {
    label: "PRINT RECEIPT",
    key: "can_print_reciept",
    value: 6,
    role: Roles.Distributor,
  },
  {
    label: "CREATE DISTRIBUTOR",
    key: "can_create_distributor",
    value: 7,
    role: Roles.Distributor,
  },
  {
    label: "CREATE ADMIN",
    key: "can_create_admin",
    value: 8,
    role: Roles.Admin,
  },
];

export const bbLimit = [{ label: 1, value: 1 }];

export const pageSize = 20;

export const bonusType = [
  { label: "10 %", value: 10 },
  { label: "20 %", value: 20 },
];

export const limitType = [
  { label: "%", value: 1 },
  { label: "USD", value: 2 },
];

export const userAction = {
  Deposit: "Deposit",
  Create_Account: "Create account",
  Reverse: "reverse",
  Close: "Close",
  Redeem: "Redeemed",
};

export const DEFAULT_TZ = "America/Los_Angeles";
export const DEFAULT_LANG = "English";

export const ANDROID_QR =
  "https://play.google.com/store/apps/details?id=com.goldenscatter.flamingo7";
export const IOS_QR =
  "https://apps.apple.com/in/app/f7fun-latest-casino-games/id1569168650";
export const ANDROID_LINK =
  "https://play.google.com/store/apps/details?id=com.goldenscatter.flamingo7";
export const IOS_LINK =
  "https://apps.apple.com/in/app/f7fun-latest-casino-games/id1569168650";
export const PC_LINK = "  https://f7game.fun";
