/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export const GET_PLAYERS = "GET_PLAYERS";
export const GET_PLAYERS_SUCCESS = "GET_PLAYERS_SUCCESS";
export const GET_PLAYERS_ERROR = "GET_PLAYERS_ERROR";

export const DEPOSITE_REQUEST = "DEPOSITE_REQUEST";
export const DEPOSITE_SUCCESS = "DEPOSITE_SUCCESS";
export const DEPOSITE_ERROR = "DEPOSITE_ERROR";

export const REDEEM_REQUEST = "REDEEM_REQUEST";
export const REDEEM_SUCCESS = "REDEEM_SUCCESS";
export const REDEEM_ERROR = "REDEEM_ERROR";

export const FREE_SPIN_AWARD_REQUEST = "FREE_SPIN_AWARD_REQUEST";
export const FREE_SPIN_AWARD_SUCCESS = "FREE_SPIN_AWARD_SUCCESS";
export const FREE_SPIN_AWARD_ERROR = "FREE_SPIN_AWARD_ERROR";

export const CREATE_PLAYER = "CREATE_PLAYER";
export const CREATE_PLAYER_SUCCESS = "CREATE_PLAYER_SUCCESS";
export const CREATE_PLAYER_ERROR = "CREATE_PLAYER_ERROR";

export const SET_STATE_REQ = "SET_STATE_REQ";
export const SET_STATE_SUCCESS = "SET_STATE_SUCCESS";
export const SET_STATE_ERROR = "SET_STATE_ERROR";

export const GET_LOGS = "GET_LOGS";
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS";
export const GET_LOGS_ERROR = "GET_LOGS_ERROR";

export const GET_CU = "GET_CU";
export const GET_CU_SUCCESS = "GET_CU_SUCCESS";
export const GET_CU_ERROR = "GET_CU_ERROR";

export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_ERROR = "GET_TRANSACTION_ERROR";

export const CASHIER_TRANSACTION = "CASHIER_TRANSACTION";
export const CASHIER_TRANSACTION_SUCCESS = "CASHIER_TRANSACTION_SUCCESS";
export const CASHIER_TRANSACTION_ERROR = "CASHIER_TRANSACTION_ERROR";

export const JACKPOT_REQUEST = "JACKPOT_REQUEST";
export const JACKPOT_SUCCESS = "JACKPOT_SUCCESS";
export const JACKPOT_ERROR = "JACKPOT_ERROR";

export const JACKPOT_CATEGORY_REQUEST = "JACKPOT_CATEGORY_REQUEST";
export const JACKPOT_CATEGORY_SUCCESS = "JACKPOT_CATEGORY_SUCCESS";
export const JACKPOT_CATEGORY_ERROR = "JACKPOT_CATEGORY_ERROR";

export const CASHIER_INDEX_REQUEST = "CASHIER_INDEX_REQUEST";
export const CASHIER_INDEX_SUCCESS = "CASHIER_INDEX_SUCCESS";
export const CASHIER_INDEX_ERROR = "CASHIER_INDEX_ERROR";

export const CREATE_CASHIER_REQUEST = "CREATE_CASHIER_REQUEST";
export const CREATE_CASHIER_SUCCESS = "CREATE_CASHIER_SUCCESS";
export const CREATE_CASHIER_ERROR = "CREATE_CASHIER_ERROR";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const LANGUAGE_REQUEST = "LANGUAGE_REQUEST";
export const LANGUAGE_SUCCESS = "LANGUAGE_SUCCESS";
export const LANGUAGE_ERROR = "LANGUAGE_ERROR";

export const TIMEZONE_REQUEST = "TIMEZONE_REQUEST";
export const TIMEZONE_SUCCESS = "TIMEZONE_SUCCESS";
export const TIMEZONE_ERROR = "TIMEZONE_ERROR";

export const REVERSE_REQUEST = "REVERSE_REQUEST";
export const REVERSE_SUCCESS = "REVERSE_SUCCESS";
export const REVERSE_ERROR = "REVERSE_ERROR";

export const DELETE_REQUEST = "DELETE_REQUEST";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_ERROR = "DELETE_ERROR";

export const STATISTICS_REQUEST = "STATISTICS_REQUEST";
export const STATISTICS_SUCCESS = "STATISTICS_SUCCESS";
export const STATISTICS_ERROR = "STATISTICS_ERROR";

export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_ERROR = "SEARCH_ERROR";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_ERROR = "USER_LIST_ERROR";

export const CHANGE_STATE_REQUEST = "CHANGE_STATE_REQUEST";
export const CHANGE_STATE_SUCCESS = "CHANGE_STATE_SUCCESS";
export const CHANGE_STATE_ERROR = "CHANGE_STATE_ERROR";

export const GET_BOUNCEBACK_REQUEST = "GET_BOUNCEBACK_REQUEST";
export const GET_BOUNCEBACK_SUCCESS = "GET_BOUNCEBACK_SUCCESS";
export const GET_BOUNCEBACK_ERROR = "GET_BOUNCEBACK_ERROR";

export const BOUNCEBACK_LIST_REQUEST = "BOUNCEBACK_LIST_REQUEST";
export const BOUNCEBACK_LIST_SUCCESS = "BOUNCEBACK_LIST_SUCCESS";
export const BOUNCEBACK_LIST_ERROR = "BOUNCEBACK_LIST_ERROR";

export const RECEIPT_REQUEST = "RECEIPT_REQUEST";
export const RECEIPT_SUCCESS = "RECEIPT_SUCCESS";
export const RECEIPT_ERROR = "RECEIPT_ERROR";

export const GET_PAYOUT_REQUEST = "GET_PAYOUT_REQUEST";
export const GET_PAYOUT_SUCCESS = "GET_PAYOUT_SUCCESS";
export const GET_PAYOUT_ERROR = "GET_PAYOUT_ERROR";

export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_ERROR = "UPDATE_ERROR";

export const HAPPY_HOURS_REQUEST = "HAPPY_HOURS_REQUEST";
export const HAPPY_HOURS_SUCCESS = "HAPPY_HOURS_SUCCESS";
export const HAPPY_HOURS_ERROR = "HAPPY_HOURS_ERROR";

export const PERMISSIONS_REQUEST = "PERMISSIONS_REQUEST";
export const PERMISSIONS_SUCCESS = "PERMISSIONS_SUCCESS";
export const PERMISSIONS_ERROR = "PERMISSIONS_ERROR";

export const JACKPOT_DETAILS_REQUEST = "JACKPOT_DETAILS_REQUEST";
export const JACKPOT_DETAILS_SUCCESS = "JACKPOT_DETAILS_SUCCESS";
export const JACKPOT_DETAILS_ERROR = "JACKPOT_DETAILS_ERROR";

export const GAMES_REQUEST = "GAMES_REQUEST";
export const GAMES_SUCCESS = "GAMES_SUCCESS";
export const GAMES_ERROR = "GAMES_ERROR";

export const UPDATE_GAMES_REQUEST = "UPDATE_GAMES_REQUEST";
export const UPDATE_GAMES_SUCCESS = "UPDATE_GAMES_SUCCESS";
export const UPDATE_GAMES_ERROR = "UPDATE_GAMES_ERROR";

export const UPDATE_CASHIER_REQUEST = "UPDATE_CASHIER_REQUEST";
export const UPDATE_CASHIER_SUCCESS = "UPDATE_CASHIER_SUCCESS";
export const UPDATE_CASHIER_ERROR = "UPDATE_CASHIER_ERROR";

export const REPORTS_REQUEST = "REPORTS_REQUEST";
export const REPORTS_SUCCESS = "REPORTS_SUCCESS";
export const REPORTS_ERROR = "REPORTS_ERROR";

export const SETTINGS_REQUEST = "SETTINGS_REQUEST";
export const SETTINGS_SUCCESS = "SETTINGS_SUCCESS";
export const SETTINGS_ERROR = "SETTINGS_ERROR";

export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";

export const MESSAGE_REQUEST = "MESSAGE_REQUEST";
export const MESSAGE_SUCCESS = "MESSAGE_SUCCESS";
export const MESSAGE_ERROR = "MESSAGE_ERROR";

export const RESET_MESSAGES = "RESET_MESSAGES";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./users/actions";
export * from "./reset/reducer";
