import {
  GET_PLAYERS,
  GET_PLAYERS_SUCCESS,
  GET_PLAYERS_ERROR,
  DEPOSITE_REQUEST,
  DEPOSITE_SUCCESS,
  DEPOSITE_ERROR,
  REDEEM_REQUEST,
  REDEEM_SUCCESS,
  REDEEM_ERROR,
  FREE_SPIN_AWARD_REQUEST,
  FREE_SPIN_AWARD_SUCCESS,
  FREE_SPIN_AWARD_ERROR,
  CREATE_PLAYER,
  CREATE_PLAYER_SUCCESS,
  CREATE_PLAYER_ERROR,
  SET_STATE_REQ,
  SET_STATE_SUCCESS,
  SET_STATE_ERROR,
  GET_LOGS,
  GET_LOGS_SUCCESS,
  GET_LOGS_ERROR,
  GET_CU,
  GET_CU_SUCCESS,
  GET_CU_ERROR,
  GET_TRANSACTION,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_ERROR,
  CASHIER_TRANSACTION,
  CASHIER_TRANSACTION_SUCCESS,
  CASHIER_TRANSACTION_ERROR,
  JACKPOT_REQUEST,
  JACKPOT_SUCCESS,
  JACKPOT_ERROR,
  JACKPOT_CATEGORY_REQUEST,
  JACKPOT_CATEGORY_SUCCESS,
  JACKPOT_CATEGORY_ERROR,
  CASHIER_INDEX_REQUEST,
  CASHIER_INDEX_SUCCESS,
  CASHIER_INDEX_ERROR,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_ERROR,
  CREATE_CASHIER_REQUEST,
  CREATE_CASHIER_SUCCESS,
  CREATE_CASHIER_ERROR,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  LANGUAGE_REQUEST,
  LANGUAGE_SUCCESS,
  LANGUAGE_ERROR,
  TIMEZONE_REQUEST,
  TIMEZONE_SUCCESS,
  TIMEZONE_ERROR,
  REVERSE_REQUEST,
  REVERSE_SUCCESS,
  REVERSE_ERROR,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_ERROR,
  STATISTICS_REQUEST,
  STATISTICS_SUCCESS,
  STATISTICS_ERROR,
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_ERROR,
  CHANGE_STATE_REQUEST,
  CHANGE_STATE_SUCCESS,
  CHANGE_STATE_ERROR,
  GET_BOUNCEBACK_REQUEST,
  GET_BOUNCEBACK_SUCCESS,
  GET_BOUNCEBACK_ERROR,
  BOUNCEBACK_LIST_REQUEST,
  BOUNCEBACK_LIST_SUCCESS,
  BOUNCEBACK_LIST_ERROR,
  RECEIPT_REQUEST,
  RECEIPT_SUCCESS,
  RECEIPT_ERROR,
  GET_PAYOUT_REQUEST,
  GET_PAYOUT_SUCCESS,
  GET_PAYOUT_ERROR,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
  HAPPY_HOURS_REQUEST,
  HAPPY_HOURS_SUCCESS,
  HAPPY_HOURS_ERROR,
  PERMISSIONS_REQUEST,
  PERMISSIONS_SUCCESS,
  PERMISSIONS_ERROR,
  JACKPOT_DETAILS_REQUEST,
  JACKPOT_DETAILS_SUCCESS,
  JACKPOT_DETAILS_ERROR,
  GAMES_REQUEST,
  GAMES_SUCCESS,
  GAMES_ERROR,
  UPDATE_GAMES_REQUEST,
  UPDATE_GAMES_SUCCESS,
  UPDATE_GAMES_ERROR,
  UPDATE_CASHIER_REQUEST,
  UPDATE_CASHIER_SUCCESS,
  UPDATE_CASHIER_ERROR,
  REPORTS_REQUEST,
  REPORTS_SUCCESS,
  REPORTS_ERROR,
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_ERROR,
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_ERROR,
  MESSAGE_REQUEST,
  MESSAGE_SUCCESS,
  MESSAGE_ERROR,
} from "../actions";

import _ from "lodash";

const INIT_STATE = {
  // users_list: [],
  logsList: [],
  CuList: [],
  transactionList: [],
  CtList: [],
  jackpotList: [],
  JcList: [],
  CiList: [],
  bbList: [],
  list: [],
  hours_list: [],
  payout: [],
  users: [],
  user: {},
  permissions: {},
  receipt: {},
  language: [],
  timeZone: [],
  searchList: [],
  createCMessage: "",
  createCError: "",
  redeemMessage: "",
  redeemError: "",
  createPlayerMessage: "",
  createPlayerError: "",
  setStateMessage: "",
  setStateError: "",
  reverseMessage: "",
  reverseError: "",
  deleteMessage: "",
  deleteError: "",
  logsError: "",
  CuError: "",
  transactionError: "",
  CtError: "",
  jackpotError: "",
  JcError: "",
  CiError: "",
  userError: "",
  languageError: "",
  timeZoneError: "",
  statsError: "",
  searchError: "",
  getPlayersReq: false,
  createPlayerReq: false,
  depositeReq: false,
  redeemReq: false,
  setStateReq: false,
  getLogsReq: false,
  getCUReq: false,
  transactionReq: false,
  CtReq: false,
  jackpotReq: false,
  JcReq: false,
  CiReq: false,
  createCReq: false,
  getUserReq: false,
  languageReq: false,
  timeZoneReq: false,
  reverseReq: false,
  deleteReq: false,
  statsReq: false,
  userListReq: false,
  userListError: false,
  searchReq: "",
  successMessage: "",
  successError: "",
  loader: false,

  users_list: [],
  players_list: [],
  stats_list: [],
  transaction_list: [],
  jackpot_category_list: [],
  games_list: [],
  history_list: [],
  cashier_list: [],
  logs_list: [],
  jackpot_history: [],
  report_list: [],
  dashboard_data: {},
  count: 0,

  jackpot_details: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLAYERS:
      return { ...state, error: "", loader: true };
    case GET_PLAYERS_SUCCESS:
      return {
        ...state,
        players_list: action.payload.users,
        count: action.payload.count,
        loader: false,
      };
    case GET_PLAYERS_ERROR:
      return {
        ...state,
        users_list: "",
        loader: false,
        successError: action.payload.message,
      };
    case DEPOSITE_REQUEST:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case DEPOSITE_SUCCESS: {
      let useState =
        window.location.pathname === "/admin/users" ||
        window.location.pathname === "/distributor/users"
          ? state.users_list
          : state.players_list;
      let newList = [];
      if (action.payload && action.payload.player) {
        const objIndex = useState.findIndex(
          (obj) => obj._id === action.payload.player.user_id._id
        );
        newList = useState;
        if (newList[objIndex].wallet.length) {
          newList[objIndex].wallet[0].amount = action.payload.player.amount;
          useState &&
            (newList[objIndex].wallet[0].wager_bonus_amount =
              action.payload.player.wager_bonus_amount);
        } else newList[objIndex].wallet.amount = action.payload.player.amount;
      }
      return {
        ...state,
        loader: false,
        successError: "",
        successMessage: action.payload.message,
      };
    }
    case DEPOSITE_ERROR:
      let useState =
        window.location.pathname === "/admin/users" ||
      window.location.pathname === "/distributor/users"
          ? state.users_list
          : state.players_list;
      let newList = [];
      
      if (action.payload && action.payload.player && action.payload.player.data) {
        const objIndex = useState.findIndex(
          (obj) => obj._id === action.payload.player.data.user_id._id
        );
        newList = useState;
        if (newList[objIndex].wallet.length)
          newList[objIndex].wallet[0].amount = action.payload.player.data.amount;
        else newList[objIndex].wallet.amount = action.payload.player.data.amount;
      }
      return {
        ...state,
        loader: false,
        successMessage: "",
        successError: action.payload.message,
      };
    case REDEEM_REQUEST:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case REDEEM_SUCCESS: {
      let useState =
        window.location.pathname === "/admin/users" ||
        window.location.pathname === "/distributor/users"
          ? state.users_list
          : state.players_list;
      let newList = [];
      if (action.payload && action.payload.player) {
        const objIndex = useState.findIndex(
          (obj) => obj._id === action.payload.player.user_id
        );
        newList = useState;
        if (newList[objIndex].wallet.length) {
          newList[objIndex].wallet[0].amount = action.payload.player.amount;
          newList[objIndex].wallet[0].redeemed_amount =
            action.payload.player.redeemed_amount;
          newList[objIndex].wallet[0].wager_bonus_amount =
            action.payload.player.wager_bonus_amount;
        }
      }
      return {
        ...state,
        loader: false,
        successError: "",
        successMessage: action.payload.message,
      };
    }
    case REDEEM_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        successError: action.payload.message,
      };
    case FREE_SPIN_AWARD_REQUEST:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case FREE_SPIN_AWARD_SUCCESS: {

      return {
        ...state,
        loader: false,
        successError: "",
        successMessage: action.payload.message,
      };
    };
    case FREE_SPIN_AWARD_ERROR:
        return {
          ...state,
          loader: false,
          successMessage: "",
          successError: action.payload.message,
        };
    case CREATE_PLAYER:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case CREATE_PLAYER_SUCCESS: {
      let newList = [...state.players_list];
      let newPLayer = action.payload.player.user_id;

      newPLayer["wallet"] = [
        {
          amount: action.payload.player.amount,
          redeemed_amount: action.payload.player.redeemed_amount,
          _id: action.payload.player._id,
          wager_bonus_amount: action.payload.player.wager_bonus_amount,
        },
      ];
      newList.unshift(newPLayer);
      return {
        ...state,
        players_list: [...newList],
        loader: false,
        successError: "",
        successMessage: action.payload.message,
      };
    }
    case CREATE_PLAYER_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        successError: action.payload.message,
      };
    case SET_STATE_REQ:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case SET_STATE_SUCCESS: {
      let newList = [];
      let useState =
        window.location.pathname === "/shop/cashier-index"
          ? state.users_list
          : state.players_list;
      if (action.payload && action.payload.player) {
        const objIndex = useState.findIndex(
          (obj) => obj._id === action.payload.player._id
        );
        newList = useState;
        if (objIndex < 0) {
          newList = useState;
        } else if (action.payload.player.status === 3) {
          useState.splice(objIndex, 1);
        } else {
          newList[objIndex]["status"] = action.payload.player.status;
        }
      }
      return {
        ...state,
        loader: false,
        successError: "",
        players_list: [...newList],
        successMessage: action.payload.message,
      };
    }
    case SET_STATE_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        setStateError: action.payload.message,
      };
    case USER_LIST_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case USER_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        users_list: action.payload.users,
        count: action.payload.count,
      };
    case USER_LIST_ERROR:
      return {
        ...state,
        loader: false,
        successError: action.payload.message,
      };

    case GET_LOGS:
      return { ...state, loader: true, successError: "" };
    case GET_LOGS_SUCCESS:
      return {
        ...state,
        loader: false,
        logs_list: action.payload.logs,
        count: action.payload.count,
      };
    case GET_LOGS_ERROR:
      return {
        ...state,
        loader: false,
        logs_list: [],
        successError: action.payload.message,
      };
    case GET_CU:
      return { ...state, successError: "" };
    case GET_CU_SUCCESS:
      return { ...state, cashier_list: action.payload.cashier };
    case GET_CU_ERROR:
      return {
        ...state,
        cashier_list: "",
        successError: action.payload.message,
      };
    case GET_TRANSACTION:
      return { ...state, loader: true, successError: "" };
    case GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        loader: false,
        history_list: action.payload.list,
        count: action.payload.count,
      };
    case GET_TRANSACTION_ERROR:
      return {
        ...state,
        loader: false,
        history_list: [],
        successError: action.payload.message,
      };
    case CASHIER_TRANSACTION:
      return { ...state, loader: true, successError: "" };
    case CASHIER_TRANSACTION_SUCCESS:
      return {
        ...state,
        loader: false,
        transaction_list: action.payload.transactions,
        count: action.payload.count,
      };
    case CASHIER_TRANSACTION_ERROR:
      return {
        ...state,
        loader: false,
        transaction_list: "",
        successError: action.payload.message,
      };
    case JACKPOT_REQUEST:
      return { ...state, loader: true, successError: "" };
    case JACKPOT_SUCCESS:
      return {
        ...state,
        loader: false,
        jackpot_history: action.payload.jackpot,
        count: action.payload.count,
      };
    case JACKPOT_ERROR:
      return {
        ...state,
        loader: false,
        jackpot_history: [],
        successError: action.payload.message,
      };
    case JACKPOT_CATEGORY_REQUEST:
      return { ...state, loader: true, successError: "" };
    case JACKPOT_CATEGORY_SUCCESS:
      return {
        ...state,
        loader: false,
        jackpot_category_list: action.payload.categories,
      };
    case JACKPOT_CATEGORY_ERROR:
      return {
        ...state,
        loader: false,
        jackpot_category_list: "",
        successError: action.payload.message,
      };
    case CASHIER_INDEX_REQUEST:
      return { ...state, CiReq: true, CiError: "" };
    case CASHIER_INDEX_SUCCESS:
      return { ...state, CiReq: false, CiList: action.payload.cashiers };
    case CASHIER_INDEX_ERROR:
      return {
        ...state,
        CiReq: false,
        CiList: "",
        CiError: action.payload.message,
      };
    case CREATE_CASHIER_REQUEST:
      return { ...state, loader: true, successError: "" };
    case CREATE_CASHIER_SUCCESS:
      return {
        ...state,
        loader: false,
        successMessage: action.payload.message,
      };
    case CREATE_CASHIER_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        successError: action.payload.message,
      };
    case GET_USER_REQUEST:
      return { ...state, getUserReq: true, userError: "" };
    case GET_USER_SUCCESS:
      return { ...state, getUserReq: false, user: action.payload.user };
    case GET_USER_ERROR:
      return {
        ...state,
        getUserReq: false,
        user: "",
        userError: action.payload.message,
      };
    case LANGUAGE_REQUEST:
      return { ...state, languageReq: true, languageError: "" };
    case LANGUAGE_SUCCESS:
      return {
        ...state,
        languageReq: false,
        language: action.payload.languages,
      };
    case LANGUAGE_ERROR:
      return {
        ...state,
        languageReq: false,
        language: "",
        languageError: action.payload.message,
      };
    case TIMEZONE_REQUEST:
      return { ...state, timeZoneReq: true, timeZoneError: "" };
    case TIMEZONE_SUCCESS:
      return {
        ...state,
        timeZoneReq: false,
        timeZone: action.payload.timeZones,
      };
    case TIMEZONE_ERROR:
      return {
        ...state,
        timeZoneReq: false,
        timeZone: "",
        timeZoneError: action.payload.message,
      };
    case REVERSE_REQUEST:
      return { ...state, reverseReq: true, reverseError: "" };
    case REVERSE_SUCCESS:
      return {
        ...state,
        reverseReq: false,
        successMessage: action.payload.message,
      };
    case REVERSE_ERROR:
      return {
        ...state,
        reverseReq: false,
        successMessage: "",
        successError: action.payload.message,
      };
    case DELETE_REQUEST:
      return { ...state, deleteReq: true, deleteError: "" };
    case DELETE_SUCCESS:
      return {
        ...state,
        deleteReq: false,
        successMessage: action.payload.message,
      };
    case DELETE_ERROR:
      return {
        ...state,
        deleteReq: false,
        successMessage: "",
        successError: action.payload.message,
      };
    case STATISTICS_REQUEST:
      return { ...state, loader: true, successError: "" };
    case STATISTICS_SUCCESS:
      return {
        ...state,
        loader: false,
        stats_list: action.payload.users,
        count: action.payload.count,
      };
    case STATISTICS_ERROR:
      return {
        ...state,
        loader: false,
        stats_list: "",
        successError: action.payload.message,
      };
    case SEARCH_REQUEST:
      return { ...state, searchReq: true, searchError: "" };
    case SEARCH_SUCCESS:
      return { ...state, searchReq: false, searchList: action.payload.user };
    case SEARCH_ERROR:
      return {
        ...state,
        searchReq: false,
        searchList: "",
        searchError: action.payload.message,
      };

    case CHANGE_STATE_REQUEST:
      return {
        ...state,
        getPlayersReq: true,
        successError: "",
        successMessage: "",
      };
    case CHANGE_STATE_SUCCESS: {
      return {
        ...state,
        getPlayersReq: false,
        successError: "",
        successMessage: action.payload.message,
      };
    }
    case CHANGE_STATE_ERROR:
      return {
        ...state,
        getPlayersReq: false,
        successMessage: "",
        setStateError: action.payload.message,
      };

    case GET_BOUNCEBACK_REQUEST:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case GET_BOUNCEBACK_SUCCESS: {
      return {
        ...state,
        loader: false,
        successError: "",
        successMessage: action.payload.message,
        bbList: action.payload.list,
      };
    }
    case GET_BOUNCEBACK_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        setStateError: action.payload.message,
      };

    case BOUNCEBACK_LIST_REQUEST:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case BOUNCEBACK_LIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        successError: "",
        successMessage: action.payload.message,
        list: action.payload.list,
      };
    }
    case BOUNCEBACK_LIST_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        setStateError: action.payload.message,
      };

    case RECEIPT_REQUEST:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case RECEIPT_SUCCESS: {
      return {
        ...state,
        loader: false,
        successError: "",
        successMessage: action.payload.message,
        receipt: action.payload.receipt,
      };
    }
    case RECEIPT_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        setStateError: action.payload.message,
      };

    case GET_PAYOUT_REQUEST:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case GET_PAYOUT_SUCCESS: {
      return {
        ...state,
        loader: false,
        successError: "",
        successMessage: action.payload.message,
        payout: action.payload.list,
      };
    }
    case GET_PAYOUT_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        setStateError: action.payload.message,
      };

    case UPDATE_REQUEST:
      return {
        ...state,
        getPlayersReq: true,
        successError: "",
        successMessage: "",
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        getPlayersReq: false,
        successError: "",
        successMessage: action.payload.message,
      };
    case UPDATE_ERROR:
      return {
        ...state,
        getPlayersReq: false,
        successMessage: "",
        setStateError: action.payload.message,
      };

    case HAPPY_HOURS_REQUEST:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case HAPPY_HOURS_SUCCESS:
      return {
        ...state,
        loader: false,
        successError: "",
        successMessage: action.payload.message,
        hours_list: action.payload.hours_list,
      };

    case HAPPY_HOURS_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        setStateError: action.payload.message,
      };

    case PERMISSIONS_REQUEST:
      return {
        ...state,
        getPlayersReq: true,
        successError: "",
        successMessage: "",
      };
    case PERMISSIONS_SUCCESS:
      return {
        ...state,
        getPlayersReq: false,
        successError: "",
        successMessage: action.payload.message,
        permissions: action.payload.permissions,
      };

    case PERMISSIONS_ERROR:
      return {
        ...state,
        getPlayersReq: false,
        successMessage: "",
        setStateError: action.payload.message,
      };

    case JACKPOT_DETAILS_REQUEST:
      return { ...state, loader: true, successError: "" };
    case JACKPOT_DETAILS_SUCCESS:
      return {
        ...state,
        loader: false,
        jackpot_details: action.payload.category,
        successMessage:
          action.payload.message && action.payload.message.message,
      };
    case JACKPOT_DETAILS_ERROR:
      return {
        ...state,
        loader: false,
        successError: action.payload.message,
      };

    case GAMES_REQUEST:
      return { ...state, loader: true, successError: "" };
    case GAMES_SUCCESS:
      return {
        ...state,
        loader: false,
        games_list: action.payload.games,
        count: action.payload.count,
      };
    case GAMES_ERROR:
      return {
        ...state,
        loader: false,
        games_list: [],
        successError: action.payload.message,
      };

    case UPDATE_GAMES_REQUEST:
      return { ...state, loader: true, successError: "" };
    case UPDATE_GAMES_SUCCESS:
      const objIndex = state.games_list.findIndex(
        (obj) => obj._id === action.payload.game._id
      );
      state.games_list.splice(objIndex, 1, action.payload.game);
      return {
        ...state,
        loader: false,
      };
    case UPDATE_GAMES_ERROR:
      return {
        ...state,
        loader: false,
        successError: action.payload.message,
      };

    case UPDATE_CASHIER_REQUEST:
      return { ...state, successError: "" };
    case UPDATE_CASHIER_SUCCESS:
      return {
        ...state,
        loader: false,
        successMessage: action.payload.message,
      };
    case UPDATE_CASHIER_ERROR:
      return {
        ...state,
        loader: false,
        successError: action.payload.message,
      };

    case REPORTS_REQUEST:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case REPORTS_SUCCESS:
      return {
        ...state,
        loader: false,
        report_list: action.payload.list,
      };

    case REPORTS_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        setStateError: action.payload.message,
      };

    case SETTINGS_REQUEST:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case SETTINGS_SUCCESS:
      return {
        ...state,
        loader: false,
        successMessage: action.payload.message,
      };

    case SETTINGS_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        setStateError: action.payload.message,
      };

    case DASHBOARD_REQUEST:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        loader: false,
        dashboard_data: action.payload.data,
      };

    case DASHBOARD_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        setStateError: action.payload.message,
      };

    case MESSAGE_REQUEST:
      return {
        ...state,
        loader: true,
        successError: "",
        successMessage: "",
      };
    case MESSAGE_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.message,
        successError: "",
        loader: false,
      };

    case MESSAGE_ERROR:
      return {
        ...state,
        loader: false,
        successMessage: "",
        setStateError: action.payload.message,
      };

    default:
      return { ...state };
  }
};
