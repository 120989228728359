import { RESET_MESSAGES } from "../actions";

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_MESSAGES:
      return {
        ...state,
        // loading: false,
        // error: action.payload.message,
        // message: "",
      };
    default:
      return { ...state };
  }
};
