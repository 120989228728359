import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
import players from "./users/reducer";
import reset from "./reset/reducer";
const appReducers = combineReducers({
  menu,
  settings,
  authUser,
  players,
  reset,
});

const reducers = (state, action) => {
  if (action.type === "RESET_MESSAGES") {
    let arr = [
      { reducer: "authUser", state: "message" },
      { reducer: "authUser", state: "error" },
      { reducer: "players", state: "successMessage" },
      { reducer: "players", state: "successError" },
    ];
    arr.map((item) => {
      state[item.reducer][item.state] = "";
    });
  }

  return appReducers(state, action);
};

export default reducers;
